<template>
  <v-container>
    <h2 v-if="!checkedIn">
      När ni blir incheckade på lunchstationen kan ni avsluta lunchen här.
      Efter 45 minuter av lunch eller när ni checkat ut själv, kan ni hämta
      koden till nästa station via denna sida.
    </h2>
    <div v-else>
      <div v-if="!checkedOut">
        <p>Incheckad för obligatorisk lunch kl. {{ lunchIn }}.</p>
        <p>Rallytimern har pausats och tiden ni är på lunchstationen kommer inte räknas med i rallytimern.</p>
        <p>Er lunchpaus får vara mellan 45-60 minuter. Om ni är nöjda och vill köra igång efter 45 minuter kan ni klicka på knappen nedanför.</p>
        <p>Efter 60 minuter avslutar Anna er lunchtid automatiskt.</p>
        <p>När lunchen är avslutad dyker en ny knapp upp som ni kan klicka på för att låsa upp nästa station.</p>
        <p>Boten Anna önskar er en MumSig måltid!</p>
        <div class="text-center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- Disable button if there is no more help to be given -->
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Mätt
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                Är du säker?
              </v-card-title>
              <v-card-text>Rallytimern kommer starta igen och ni får koden till nästan station</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="checkOutEarly"
                >
                  Ja
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false"
                >
                  Nej
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div v-else>
        <h4>Utcheckad från lunch</h4>
        <p>Lycka till på resterande resa!</p>
        <v-btn color="primary" @click="getToken">Öppna nästa station</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import {notify} from '../../utils/notification'
import { useGeolocation } from '@vueuse/core'

export default {
  name: 'Lunch',
  data: () => ({
    dialog: false,
    checkedIn: false,
    checkedOut: false,
    team: null,
    lunchIn: null,
    lunchOut: null,
    latestCheckOutTime: null,
    token: null,
    unlockedStations: null,
    coords: null,
    coordsError: null,
    timer: null,
  }),
  methods: {
    ...mapActions(["unlockStation", "getTeamUnlocks", "getTeam", "clearNotifications", "lunchCheckOut", "getLunchToken"]),
    async updateTeamUnlocks() {
      const data = await this.getTeamUnlocks()
      this.unlockedStations = data
    },
    async getUserTeam() {
      const team = await this.getTeam()
      this.team = team

      if (team.t_ts_lunch_in) {
        this.checkedIn = true
        // this.lunchIn = team.t_ts_lunch_in

        const date = new Date(team.t_ts_lunch_in)
        const timestampWithOffset = date.getTime()
        const dateWithOffset = new Date(timestampWithOffset)
        // Extract the time
        this.lunchIn = dateWithOffset.toLocaleTimeString()
        // Also add the time for latest check out
        this.latestCheckOutTime = new Date(timestampWithOffset+1000*60*45).toLocaleTimeString()
      }

      // Team has been checked out
      if (team.t_ts_lunch_out) {
        this.checkedOut = true
      }
    },
    async checkOutEarly() {
      // await this.lunchCheckOut(parsedTeam.t_start_position)
      // console.log(this.team.t_start_position)
      await this.lunchCheckOut(this.team.t_start_position)

      // Notification
      const success = notify(this)
      if (success) {
        this.getUserTeam()
      } else {
        this.dialog = false
      }
    },
    async getToken() {
      const token = await this.getLunchToken()
      await this.unlockStation({
        stationNumber: this.unlockedStations + 1, 
        token: token,
        latitude: this.coords.latitude,
        longitude: this.coords.longitude,
      })
      await this.updateTeamUnlocks()
      // Notification
      notify(this)
    }


  },
  mounted() {
    this.getUserTeam()
    this.updateTeamUnlocks()
    const { coords, error } = useGeolocation()
    this.coords = coords
    this.coordsError = error
    this.timer = setInterval(() => {
      this.updateTeamUnlocks()
    }, 5000)
  }, 
  beforeDestroy() {
    clearInterval(this.timer)
  },
  computed: mapGetters(["notification"])
}
</script>

<style>

</style>